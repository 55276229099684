// PDFViewer.js
import React from 'react';
import MegazinePdfOne from "../pdf/Specialist_2022_Diabetes and the Risk of HF_V2.pdf";

const PdfViewerOne = () => {

  return (
    <div className="pdf-viewer mobile" >

          <div className="pdf-viewer">
            <object data={MegazinePdfOne} type="application/pdf" width="100%" height="800">
              <embed src={MegazinePdfOne} type="application/pdf" width="100%" height="800" />
            </object>
          </div>

    </div>
  );
};

export default PdfViewerOne;
