

// import React, { useState } from "react";
// import "../styles/HomePage.css";
// import Logo from "../images/Specialist_Logo.png";
// import MegazinePdfOne from "../pdf/Specialist_2022_Diabetes and the Risk of HF_V2.pdf";
// import MegazinePdfTwo from "../pdf/SPECIALIST_ACC_2014_1.pdf";
// import MegazinePdfThree from "../pdf/SPECIALIST_ACC_2014_2.pdf";
// import MegazineFour from "../pdf/SPECIALIST_ACC_2014_3.pdf";

// const HomePage = () => {
//   const [selectedPdf, setSelectedPdf] = useState(null); // State to track selected PDF

//   const options = [
//     { label: "Magazine one", value: MegazinePdfOne },
//     { label: "Magazine two", value: MegazinePdfTwo },
//     { label: "Magazine three", value: MegazinePdfThree },
//     { label: "Magazine four", value: MegazineFour },
//   ];

//   return (
//     <div className="Home-page-main-wrapper">
//       <div className="nav-bar-with-logo-wrapper">
//         <div className="logo">
//           <img src={Logo} alt="Specialist" />
//         </div>

//         <div className="links">
//           <p>About Us</p>
//           <nav>
//             <ul>
//               <li className="mainli">
//                 <a>Magazine &#8744;</a>
//                 <ul>
//                   {options.map((option, index) => (
//                     <li key={index}>
//                       <a onClick={() => setSelectedPdf(option.value)}>
//                         {option.label}
//                       </a>
//                     </li>
//                   ))}
//                 </ul>
//               </li>
//             </ul>
//           </nav>
//           <p>TV</p>
//           <p>Subscribe</p>
//           <p>Login</p>
//         </div>
//       </div>

//       <div className="main-content">
       
//         {selectedPdf && (
//           <div className="pdf-viewer">
              
//             <iframe
//               src={selectedPdf}
//               width="100%"
//               height="800px"
//               title="PDF Viewer"
//             />
//           </div>
//         )}
//         <p>
//           We are currently improving your viewing experience. Please visit us
//           back after 4 weeks.
//         </p>
//         <p>
//           Until then please reach out to <span>Specialist</span> printed
//           magazines in all popular newsstands and book stores.
//         </p>

//         <p>
//           In case of any queries, please reach out to us @ +91 80 - 48511458 or
//           specialistincardio@gmail.com
//         </p>
//       </div>
//     </div>
//   );
// };

// export default HomePage;







import React, { useState } from "react";
import "../styles/HomePage.css";
import Logo from "../images/Specialist_Logo.png";
import MegazinePdfOne from "../pdf/Specialist_2022_Diabetes and the Risk of HF_V2.pdf";
import MegazinePdfTwo from "../pdf/SPECIALIST_ACC_2014_1.pdf";
import MegazinePdfThree from "../pdf/SPECIALIST_ACC_2014_2.pdf";
import MegazineFour from "../pdf/SPECIALIST_ACC_2014_3.pdf";
import { useNavigate } from 'react-router-dom';
const HomePage = () => {
  const [selectedPdf, setSelectedPdf] = useState(null); // State to track selected PDF
const navigate=useNavigate();
  const options = [
    { label: "Magazine one", value: MegazinePdfOne },
    { label: "Magazine two", value: MegazinePdfTwo },
    { label: "Magazine three", value: MegazinePdfThree },
    { label: "Magazine four", value: MegazineFour },
  ];

  const renderPdf = (pdfUrl) => {
    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setSelectedPdf(url);
      })
      .catch((error) => {
        console.error("Error loading PDF:", error);
      });
  };

  return (
    <div className="Home-page-main-wrapper">
      <div className="nav-bar-with-logo-wrapper">
        <div className="logo">
          <img src={Logo} alt="Specialist" />
        </div>
        <div className="links">
          <p className="link">About Us</p>

          <nav className="desktop-nav">
            <ul>
              <li className="mainli">
                <a>Magazine &#8744;</a>
                <ul>
                  {options.map((option, index) => (
                    <li key={index}>
                      <a onClick={() => renderPdf(option.value)}>
                        {option.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
          <nav className="mobile-nav">
            <ul>
              <li className="mainli">
                <a>Magazine &#8744;</a>
                <ul>
                  {/* {options.map((option, index) => ( */}
                    <li>
                      <a onClick={() => navigate("pdf-viewer-one")}>
                      Magazine one
                      </a>
                    </li>
                    <li>
                      <a onClick={() => navigate("pdf-viewer-two")}>
                      Magazine two
                      </a>
                    </li>
                    <li>
                      <a onClick={() => navigate("pdf-viewer-three")}>
                      Magazine three
                      </a>
                    </li>
                    <li>
                      <a onClick={() => navigate("pdf-viewer-four")}>
                      Magazine four
                      </a>
                    </li>
                {/* //   ))} */}
                </ul>
              </li>
            </ul>
          </nav>
          <p className="link">TV</p>
          <p className="link">Subscribe</p>
          <p className="link">Login</p>
        </div>
      </div>

      <div className="main-content">
        {selectedPdf && (
          <div className="pdf-viewer">
            <object data={selectedPdf} type="application/pdf" width="100%" height="800">
              <embed src={selectedPdf} type="application/pdf" width="100%" height="800" />
            </object>
          </div>
        )}
        <p>
          We are currently improving your viewing experience. Please visit us
          back after 4 weeks.
        </p>
        <p>
          Until then please reach out to <span>Specialist</span> printed
          magazines in all popular newsstands and book stores.
        </p>

        <p>
          In case of any queries, please reach out to us @ +91 80 - 48511458 or
          specialistincardio@gmail.com
        </p>
      </div>
    </div>
  );
};

export default HomePage;




