import logo from "./logo.svg";
import "./App.css";
import HomePage from "./pages/HomePage";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import PdfViewerOne from "./pages/PdfViewerOne";
import PdfViewerTwo from "./pages/PdfViewerTwo";
import PdfViewerThree from "./pages/PdfViewerThree";
import PdfViewerFour from "./pages/PdfViewerFour";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pdf-viewer-one" element={<PdfViewerOne />} />
        <Route path="/pdf-viewer-two" element={<PdfViewerTwo />} />{" "}
        <Route path="/pdf-viewer-three" element={<PdfViewerThree />} />{" "}
        <Route path="/pdf-viewer-four" element={<PdfViewerFour />} />{" "}
      </Routes>
    </div>
  );
}

export default App;
