// PDFViewer.js
import React from 'react';
import MegazineFour from "../pdf/SPECIALIST_ACC_2014_3.pdf";
const PdfViewerFour = () => {

  return (
    <div className="pdf-viewer mobile" >

          <div className="pdf-viewer">
            <object data={MegazineFour} type="application/pdf" width="100%" height="800">
              <embed src={MegazineFour} type="application/pdf" width="100%" height="800" />
            </object>
          </div>

    </div>
  );
};

export default PdfViewerFour;
